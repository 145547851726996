@import "shared/styles/Variables.scss";

.content {
  display: flex;
  flex: 1 1 auto;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  padding-top: 20px;
}

.tab {
  display: block;
  align-items: center;
  color: inherit;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  border-bottom: 3px solid transparent;
  margin-right: 20px;
  white-space: nowrap;
  &:last-child {
    margin-right: 0;
  }
  &:hover, &:focus, &:active {
    color: $blue2;
  }
  &.active {
    color: $blue2;
    border-color: $blue2;
  }
}
