.content {
  margin: 2rem;
  padding: 2rem 0 ;
  background: #fff;
}

.icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}
