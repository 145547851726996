@import "shared/styles/Variables.scss";

.root {
  padding-top: 64px;
}

:global .bp3-editable-text:local.title {
  padding: 0;
  font-size: 3rem;
  font-weight: 600;
  height: 3.5rem;
  line-height: 1;
  border-color: transparent;
  outline: none;
  resize: none;
  &:hover:before {
    box-shadow: none !important;
  }
  &:global.bp3-editable-text-editing:before {
    border: none !important;
    box-shadow: none !important;
  }
}

.input {
  width: 100%;
  min-width: 30rem;
}

.contentWrapper {
  @media (min-width: $large-screen-min) {
    margin: 30px 4.5rem 0 4.5rem;
  }
}
.editorWrapper {
  margin-top: 2rem;
  position: relative;
  font-size: 18px;
}

:global .public-DraftEditor-content {
  min-height: calc(100vh - 150px)
}

// Toolbar

.buttonWrapper {
  display: inline-block;
  + .buttonWrapper {
    margin-left: 5px;
  }
}

.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px;
  color: $dark-gray1;
  background-color: #fff;
  background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0));
  box-shadow: inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1);
}

.button svg {
  fill: $dark-gray5;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.button:hover, .button:focus {
  border-color: $dark-gray5;
  outline: 0;
}

.active {
  background: $dark-gray5;
  border-color: $dark-gray5;
  color: $white;
  svg {
    vertical-align: middle;
    fill: $white;
  }
}

.actions {
  display: flex;
  align-items: center;
}

.savingStatus {
  display: inline-flex;
  color: $gray1;
  &.success {
    color: $forest3;
  }
  &.error {
    color: $red3;
  }
}

.assignee {
  display: inline-block;
  padding: 0.3em 0.5em;
  margin-left: -0.5em;
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: $light-gray3;
  }
}

