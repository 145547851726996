@import "shared/styles/Variables.scss";

.stat {
  display: flex;
  flex-direction: column;
  margin: 0 3em;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: $gray1;
  font-size: 0.8rem;
  strong {
    font-size: 2em;
    margin: 0 0.3em;
  }
}

.horizontal {
  flex-direction: row;
}
