@import "shared/styles/Variables.scss";

.root {
  display: flex;
  align-items: center;
}

.image {
  flex-grow: 0;
  flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  overflow: hidden;
  background-color: $light-gray3;
  &.loaded {
    padding: 0;
  }
  &.error {
    display: none;
  }
}

.label {
  margin-left: 0.3em;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
