@import 'shared/styles/Variables.scss';

.root {
  text-align: center;
}

.device {
  cursor: pointer;
  img {
    display: block;
    max-height: 24px;
    transition: 0.3s all ease-in-out;
  }
  &.rotate {
    &:hover, &:focus {
      img {
        transform: rotate(90deg);
      }
    }
  }
  &.landscape {
    img {
      transform: rotate(90deg);
    }
    &.rotate {
      &:hover, &:focus {
        img {
          transform: rotate(0deg);
        }
      }
    }
  }
}
:global .smartphone {
  max-height: 98%;
}
:global .tablet {
  max-height: 100%;
}
:global .monitor {
  max-height: 95%;
}
