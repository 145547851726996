/*
  This loop will create "margin helpers" and "padding helpers" for use in your web projects.
  It will generate several classes such as:
  .m-r-10 which gives margin-right 10 pixels.
  .m-r-15 gives MARGIN to the RIGHT 15 pixels.
  .m-t-15 gives MARGIN to the TOP 15 pixels and so on.
  .p-b-5 gives PADDING to the BOTTOM of 5 pixels
  .p-l-40 gives PADDING to the LEFT of 40 pixels
  The first letter is "m" or "p" for MARGIN or PADDING
  Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
  Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

/*
  Display types
*/

.block {
  display: block;
}
.in-block {
  display: inline-block;
}

/*
  Size helpers
*/

.fill {
  width: 100%;
  height: 100%;
}

/*
  Alignment helpers
*/

.align-right {
  margin-left: auto;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-sb {
  justify-content: space-between;
}
