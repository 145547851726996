@import "shared/styles/Variables.scss";

.root {
  margin: -12px -16px 12px -16px;
  padding: 0 16px;
  height: 64px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid $light-gray2;
  color: $dark-gray2;
  background-color: $white;
}

.fixed {
  position: fixed;
  top: 0;
  left: 240px;
  right: 0;
  margin: 0;
  transition: 0.3s left ease-in-out;
  @media(min-width: $large-screen-min) {
    z-index: 10;
  }
}
:global .Navigation-closed :local .fixed {
  left: 60px;
  transition: none;
}
