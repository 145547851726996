@import "~@blueprintjs/core/lib/scss/variables.scss";
@import "./functions/_color.scss";

$small-screen-max: 991px;
$large-screen-min: 992px;

$border-radius-xs: 2px;
$border-radius-md: 3px;

$motive: _color(get('motive'));
$sidebarBackground: _color(get('sidebar.background'));
$sidebarColor: _color(get('sidebar.color'));
