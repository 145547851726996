@import "shared/styles/Variables.scss";

.header {
  display: flex;
  padding: 0 15px;
  margin: 20px 0;
}
.status {
  display: flex;
}

.list {
  > li {
    padding: 5px 0;
    margin-bottom: 0 !important;
    border-bottom: 1px solid $light-gray4;
    > button {
      font-weight: 500;
      font-size: 14px !important;
    }
  }
}
