@import "shared/styles/Variables.scss";

.root {
  padding: 0.8rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: initial;

  @media (min-width: $large-screen-min) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.body {
  flex-direction: column;
  padding-right: 1rem;
}

.meta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rightBox {
  display: none;
  @media (min-width: $large-screen-min) {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    text-align: right;
  }
}

.untitled {
  color: $gray4;
  font-style: italic;
}

.image, .authorImage {
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  background-color: $light-gray3;
  &.loaded {
    padding: 0;
  }
  &.error {
    > img {
      display: none;
    }
  }
}
.image {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 1rem;

  img {
    height: 100%;
    object-fit: cover;
  }
}
.name {
  color: inherit;
  h1 {
    margin-top: 0;
    margin-bottom: 0.3rem;
  }
}
