@import './Variables.scss';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
@import url('/fonts/style.css');

body {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  letter-spacing: 0.02em;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

a {
  transition: 0.2s all ease-in-out;
  &:hover, &:focus, &:active {
    color: darken($motive, 10%);
    text-decoration: none;
  }
}

figure {
  img {
    margin: 0.5em;
  }
}
img {
  max-width: 100%;
  display: flex;
}
button {
  cursor: pointer;
}

blockquote {
  margin: 0;
  padding: 2em 1em 2em 2em;
  border-left: 2px solid $gray1;
  background-color: $light-gray4;
}

/*
  Text alignments
*/

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

/*
  Text colors
*/

.text-red {
  color: $red2;
}
