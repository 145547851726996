@import "shared/styles/Variables.scss";

.root {
  border: 1px solid $light-gray3;
  border-radius: 3px;
  overflow: hidden;
  li {
    border-bottom: 1px solid $light-gray3;
    background-color: $white;
    margin: 0 !important;
    &:nth-child(even) {
      background-color: $light-gray5;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
