@import "shared/styles/Variables.scss";

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-shrink: 0;
  background-color: $sidebarBackground;
  @media (min-width: $large-screen-min) {
    position: relative;
  }
  h1 {
    margin: 0;
    font-size: 18px;
  }
}

.inner {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 12px;
  :global .bp3-icon { 
    color: $white !important;
  }
  @media (min-width: $large-screen-min) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.mainArea {
  display: flex;
  flex-direction: row;
  @media (min-width: $large-screen-min) {
    padding: 1rem 0;
  }
}

.logo{
  padding-left: 1em;
  padding-right: 1em;
  opacity: 1;
  transition: 0.6s opacity ease-in-out;
  transition-delay: 0.1s;
  @media (max-width: $small-screen-max) {
    img {
      max-height: 36px;
    }
  }
  @media (min-width: $large-screen-min) {
    &.isClosed {
      transition: none;
      transition-delay: 0;
      opacity: 0;
      padding: 0;
      width: 0;
    }
  }
}


.hamburger {
  @media (min-width: $large-screen-min) {
    margin-bottom: 1em;
  }
}

.userButton {
  display: inline-flex;
  min-width: 40px;
  min-height: 40px;
  padding: 8px;
  border-radius: $border-radius-md;
  color: rgba($sidebarColor, 0.7);
  white-space: nowrap;
  &:hover, &:focus, &:active {
    background-color: darken($sidebarBackground, 5%);
    color: $white;
    text-decoration: none;
  }
  &.active {
    background-color: transparent;
    color: $white;
    font-weight: 700;
  }
  @media (min-width: $large-screen-min) {
    width: 100%;
  }
}
.userLabel {
  display: none;
  @media (min-width: $large-screen-min) {
    display: inline;
  }
}

.isClosed {
  @media (min-width: $large-screen-min) {
    .inner {
      padding-left: 8px;
      padding-right: 8px;
      text-align: center;
      align-items: center;
    }
    .userButton {
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .hamburger {
      width: 100%;
      justify-content: center;
    }
  }
}
