.Sidebar-root {
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 4px 8px rgba(16,22,26,.2), 0 18px 46px 6px rgba(16,22,26,.2);
  background: #fff;
  width: 15vw;
  min-width: 300px;
  max-width: 800px;
  padding: 0;
  overflow: auto;
}

.Backdrop {
  opacity: 0;
  transition: opacity .2s ease-in;
}
.Transition {
  &-enter {
    transform: translateX(100%);
    &-done {
      &.Sidebar-root {
        transform: translateX(0);
        transition-property: transform;
        transition-duration: .2s;
        transition-timing-function: cubic-bezier(.4,1,.75,.9);
        transition-delay: 0;
      }
      &.Backdrop {
        opacity: 1;
        transition: opacity .2s ease-in-out;
      }
    }
  }
  &-exit {
    transform: translateX(0);
    &-active {
      &.Sidebar-root {
        transform: translateX(100%);
        transition-property: transform;
        transition-duration: .1s;
        transition-timing-function: cubic-bezier(.4,1,.75,.9);
        transition-delay: 0;
      }
    }
  }
}
