@import './Variables.scss';

input, textarea {
  width: 100%;
  border: 1px solid $gray5;
  border-radius: 3px;
  height: 30px;
  padding: 0 10px;
  line-height: 30px;
}

.bp3-inline .bp3-form-content,
.bp3-inline .bp3-input-group {
  width: 100%;
}

.bp3-form-group.bp3-inline {
  align-items: center;
  label.bp3-label {
    white-space: nowrap;
  }
}

.bp3-switch.bp3-large {
  font-size: inherit;
}

.bp3-select-popover .bp3-popover-content {
  padding: 0.4rem;
}
.bp3-select-popover .bp3-menu {
  padding: 0.4rem 0;
}

.bp3-file-input {
  width: 100%;
}

.bp3-intent-danger .bp3-file-upload-input {
  box-shadow: 0 0 0 0 rgba(219, 55, 55, 0),
    0 0 0 0 rgba(219, 55, 55, 0),
    inset 0 0 0 1px $red3,
    inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
}

.dropzone {
  padding: 20px;
  text-align: center;
  background-color: $white;
  border: 1px solid $light-gray2;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s all ease-out;
}
.dropzone:hover {
  border-color: $light-gray1;
}

.bp3-control {
  text-transform: capitalize;
}
