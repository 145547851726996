@import "shared/styles/Variables.scss";

.root {
  display: block;
  padding: 0.8rem 1rem;
  color: inherit;
}

.date {
  font-size: 0.8rem;
}

.meta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.versusTag {
  font-size: 0.6rem;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
