@import 'shared/styles/Variables.scss';

.root {
  margin: auto;
  text-align: center;
}

.device{
	display: inline-block;
	position: relative;
	box-sizing: content-box !important;

	.screen {
		width: 100%;
		position: relative;
		height: 100%;
		z-index: 3;
		background: white;
		overflow: hidden;
		display: block;
		border-radius: 1px;
		box-shadow: 0 0 0 3px #111;
	}

	.topBar, .bottomBar {
		height: 3px;
		background: black;
		width: 100%;
		display: block;
	}

	.middle-bar {
		width: 3px;
		height: 4px;
		top: 0px;
		left: 90px;
		background: black;
		position: absolute;
	}

	&.ipad{
		width: 576px;
		height: 768px;
		padding: 90px 25px;
		background: #242324;
		border-radius: 44px;

		&:before{
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			position: absolute;
			content: '';
			display: block;
			top: 4px;
			left: 4px;
			border-radius: 40px;
			background: #1e1e1e;
		}

		.camera {
			background: #3c3d3d;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 44px;
			left: 50%;
			margin-left: -5px;
			border-radius: 100%;
		}

		.topBar, .bottomBar{
			display: none;
		}

		.home {
			background: #242324;
			border-radius: 36px;
			width: 50px;
			height: 50px;
			position: absolute;
			left: 50%;
			margin-left: -25px;
			bottom: 22px;

			&:after {
				width: 15px;
				height: 15px;
				margin-top: -8px;
				margin-left: -8px;
				border: 1px solid rgba(255, 255, 255, 0.1);
				border-radius: 4px;
				position: absolute;
				display: block;
				content: '';
				top: 50%;
				left: 50%;
			}
		}

		&.landscape{
			height: 576px;
			width: 768px;
			padding: 25px 90px;

			.camera {
				left: calc(100% - 44px);
				top: 50%;
				margin-left: 0;
				margin-top: -5px;
			}

			.home {
				top: 50%;
				left: 22px;
				margin-left: 0;
				margin-top: -25px;
			}
		}

		&.silver{
			background: #bcbcbc;

			&:before{
				background: #fcfcfc;
			}

			.home{
				background: #fcfcfc;
				box-shadow: inset 0 0 0 1px #bcbcbc;

				&:after{
					border: 1px solid rgba(0, 0, 0, 0.2);
				}
			}
		}
	}

	&.macbook {
		width: 960px;
		height: 600px;
		padding: 44px 44px 76px;
		margin: 0 auto;
		background: #bebebe;
		border-radius: 34px;

		&:before {
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			position: absolute;
			content: '';
			display: block;
			top: 4px;
			left: 4px;
			border-radius: 30px;
			background: #1e1e1e;
		}

		.topBar {
			width: calc(100% + 2 * 70px);
			height: 40px;
			position: absolute;
			content: '';
			display: block;
			top: 680px;
			left: -70px;
			border-bottom-left-radius: 90px 18px;
			border-bottom-right-radius: 90px 18px;
			background: #bebebe;
			box-shadow: inset 0px -4px 13px 3px rgba(34, 34, 34, 0.6);

			&:before {
				width: 100%;
				height: 24px;
				content: '';
				display: block;
				top: 0;
				left: 0;
				background: #f0f0f0;
				border-bottom: 2px solid #aaa;
				border-radius: 5px;
				position: relative;
			}

			&:after {
				width: 16%;
				height: 14px;
				content: '';
				display: block;
				top: 0;
				background: #ddd;
				position: absolute;
				margin-left: auto;
				margin-right: auto;
				left: 0;
				right: 0;
				border-radius: 0 0 20px 20px;
				box-shadow: inset 0px -3px 10px #999;
			}
		}

		.bottomBar {
			background: transparent;
			width: calc(100% + 2 * 70px);
			height: 26px;
			position: absolute;
			content: '';
			display: block;
			top: 680px;
			left: -70px;

			&:before,
			&:after {
				height: calc(100% - 2px);
				width: 80px;
				content: '';
				display: block;
				top: 0;

				position: absolute;
			}

			&:before {
				left: 0;
				background: #f0f0f0;
				background: linear-gradient(to right, #747474 0%, #c3c3c3 5%, #ebebeb 14%, #979797 41%, #f0f0f0 80%, #f0f0f0 100%, #f0f0f0 100%);
			}

			&:after {
				right: 0;
				background: #f0f0f0;
				background: linear-gradient(to right, #f0f0f0 0%, #f0f0f0 0%, #f0f0f0 20%, #979797 59%, #ebebeb 86%, #c3c3c3 95%, #747474 100%);
			}
		}

		.camera {
			background: #3c3d3d;
			width: 10px;
			height: 10px;
			position: absolute;
			top: 20px;
			left: 50%;
			margin-left: -5px;
			border-radius: 100%;
		}

		.home {
			display: none;
		}
	}

	&.iphoneX {
		width: 375px;
		height: 812px;
		padding: 26px;
		background: #fdfdfd;
		box-shadow:inset 0 0 11px 0 black;
		border-radius: 66px;

		.overflow {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 66px;
			overflow: hidden;
		}

		.shadow {
			border-radius: 100%;
			width: 90px;
			height: 90px;
			position: absolute;
			background: radial-gradient(ellipse at center, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 60%);
		}

		.shadowTL {
			top: -20px;
			left: -20px;
		}

		.shadowTR {
			top: -20px;
			right: -20px;
		}

		.shadowBL {
			bottom: -20px;
			left: -20px;
		}

		.shadowBR {
			bottom: -20px;
			right: -20px;
		}

		&:before{
			width: calc(100% - 10px);
			height: calc(100% - 10px);
			position: absolute;
			top: 5px;
			content: '';
			left: 5px;
			border-radius: 61px;
			background: black;
			z-index: 1;
		}

		.innerShadow{
			width: calc(100% - 20px);
			height: calc(100% - 20px);
			position: absolute;
			top: 10px;
			overflow: hidden;
			left: 10px;
			border-radius: 56px;
			box-shadow: inset 0 0 15px 0 rgba(white, 0.66);
			z-index: 1;

			&:before{
				box-shadow:inset 0 0 20px 0 #FFFFFF;
				width: 100%;
				height: 116%;
				position: absolute;
				top: -8%;
				content: '';
				left: 0;
				border-radius: 200px / 112px;
				z-index: 2;
			}
		}

		.screen {
			border-radius: 40px;
			box-shadow: none;
		}

		.topBar, .bottomBar {
			width: 100%;
			position: absolute;
			height: 8px;
			background: rgba(black, 0.1);
			left: 0;
		}

		.topBar {
			top: 80px;
		}

		.bottomBar {
			bottom: 80px;
		}

		.volume, .volume:before, .volume:after, .sleep {
			width: 3px;
			background: #b5b5b5;
			position: absolute;
		}

		.volume {
			left: -3px;
			top: 116px;
			height: 32px;

			&:before {
				height: 62px;
				top: 62px;
				content: '';
				left: 0;
			}

			&:after {
				height: 62px;
				top: 140px;
				content: '';
				left: 0;
			}
		}

		.sleep {
			height: 96px;
			top: 200px;
			right: -3px;
		}

		.camera {
			width: 6px;
			height: 6px;
			top: 9px;
			border-radius: 100%;
			position: absolute;
			left: 154px;
			background: #0d4d71;
		}

		.speaker {
			height: 6px;
			width: 60px;
			left: 50%;
			position: absolute;
			top: 9px;
			margin-left: -30px;
			background: #171818;
			border-radius: 6px;
		}

		.notch {
			position: absolute;
			width: 210px;
			height: 30px;
			top: 26px;
			left: 108px;
			z-index: 4;
			background: black;
			border-bottom-left-radius: 24px;
		    border-bottom-right-radius: 24px;

		    &:before, &:after {
				content: '';
				height: 8px;
				position: absolute;
				top: 0;
				width: 8px;
		    }

			&:after {
				background: radial-gradient(circle at bottom left, transparent 0, transparent 70%, black 70%, black 100%);
				left: -8px;
			}

			&:before {
				background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%);
				right: -8px;
			}
		}

		&.landscape {
			height: 375px;
			width: 812px;

			.topBar, .bottomBar {
				width: 8px;
				height: 100%;
				top: 0;
			}

			.topBar {
				left: 80px;
			}

			.bottomBar {
				right: 80px;
				bottom: auto;
				left: auto;
			}

			.volume, .volume:before, .volume:after, .sleep {
				height: 3px;
			}

			.innerShadow:before {
				height: 100%;
				width: 116%;
				left: -8%;
				top: 0;
				border-radius: 112px / 200px;
			}

			.volume {
				bottom: -3px;
				top: auto;
				left: 116px;
				width: 32px;

				&:before {
					width: 62px;
					left: 62px;
					top: 0;
				}

				&:after {
					width: 62px;
					left: 140px;
					top: 0;
				}
			}

			.sleep {
				width: 96px;
				left: 200px;
				top: -3px;
				right: auto;
			}

			.camera {
				left: 9px;
				bottom: 154px;
				top: auto;
			}

			.speaker {
				width: 6px;
				height: 60px;
				left: 9px;
				top: 50%;
				margin-top: -30px;
				margin-left: 0;
			}

			.notch {
				height: 210px;
				width: 30px;
				left: 26px;
				bottom: 108px;
				top: auto;
				border-top-right-radius: 24px;
			    border-bottom-right-radius: 24px;
			    border-bottom-left-radius: 0;

				&:before, &:after {
					left: 0;
				}

				&:after {
					background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%);
					bottom: -8px;
					top: auto;
				}

				&:before {
					background: radial-gradient(circle at top right, transparent 0, transparent 70%, black 70%, black 100%);
					top: -8px;
				}
			}	
		}
	}
}

.device, .device div, *:before, *:after {
	transition: all 500ms cubic-bezier(0.175, 0.785, 0.320, 0.975);
}
