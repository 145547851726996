@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
@import url(/fonts/style.css);
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.SortByFilter_sort__1L1kZ {
  display: inline-block;
  margin-right: 5px;
  line-height: 30px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Dropzone_root__1zLsI {
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 3rem 16px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #e1e8ed;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s all ease-out; }

.Dropzone_root__1zLsI:hover {
  border-color: #d8e1e8; }

.Dropzone_thumbsContainer__1VDKe {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  padding: 0.4rem; }

.Dropzone_thumb__1Vlwj {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 0.4rem;
  padding: 0.2rem;
  background-color: #e1e8ed;
  border: 1px solid #ced9e0;
  border-radius: 3px; }
  .Dropzone_thumb__1Vlwj:last-child {
    margin-bottom: 0; }

.Dropzone_thumbInner__2pwAB {
  width: 100%;
  overflow: hidden;
  border-radius: 3px; }

.Dropzone_img__1ncus {
  display: block;
  width: auto; }

.Dropzone_hint__38Mp3 {
  color: #8a9ba8;
  font-size: 20px; }

.Dropzone_limits__3NsXe {
  color: #a7b6c2; }

.Dropzone_page__1hVAI {
  max-width: 100%; }
  .Dropzone_page__1hVAI canvas {
    max-width: 100%;
    height: auto !important; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ArticleItem_root__34X_b {
  padding: 0.8rem 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  color: #000;
  color: initial; }
  @media (min-width: 992px) {
    .ArticleItem_root__34X_b {
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }

.ArticleItem_content__1PS2s {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.ArticleItem_body__3aJci {
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-right: 1rem; }

.ArticleItem_meta__38GSP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.ArticleItem_rightBox__hw87_ {
  display: none; }
  @media (min-width: 992px) {
    .ArticleItem_rightBox__hw87_ {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 1fr 1fr;
      -webkit-align-items: center;
              align-items: center;
      text-align: right; } }

.ArticleItem_untitled__3Bn2S {
  color: #a7b6c2;
  font-style: italic; }

.ArticleItem_image__3rAIB, .ArticleItem_authorImage__3Ob9i {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  overflow: hidden;
  background-color: #e1e8ed; }
  .ArticleItem_image__3rAIB.ArticleItem_loaded__10YFF, .ArticleItem_authorImage__3Ob9i.ArticleItem_loaded__10YFF {
    padding: 0; }
  .ArticleItem_image__3rAIB.ArticleItem_error__1GdJw > img, .ArticleItem_authorImage__3Ob9i.ArticleItem_error__1GdJw > img {
    display: none; }

.ArticleItem_image__3rAIB {
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 1rem; }
  .ArticleItem_image__3rAIB img {
    height: 100%;
    object-fit: cover; }

.ArticleItem_name__3Co2k {
  color: inherit; }
  .ArticleItem_name__3Co2k h1 {
    margin-top: 0;
    margin-bottom: 0.3rem; }

.StatusLabel_root__pJITT {
  border: 1px solid black !important;
  background-color: rgba(255, 255, 255, 0) !important;
  color: black !important;
  text-align: center; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Header_root__1Xqk5 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  background-color: #202b33; }
  @media (min-width: 992px) {
    .Header_root__1Xqk5 {
      position: relative; } }
  .Header_root__1Xqk5 h1 {
    margin: 0;
    font-size: 18px; }

.Header_inner__3j-ZN {
  -webkit-align-items: center;
          align-items: center;
  color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  padding: 12px; }
  .Header_inner__3j-ZN .bp3-icon {
    color: #ffffff !important; }
  @media (min-width: 992px) {
    .Header_inner__3j-ZN {
      -webkit-align-items: flex-start;
              align-items: flex-start;
      -webkit-flex-direction: column;
              flex-direction: column; } }

.Header_mainArea__2OuAk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  @media (min-width: 992px) {
    .Header_mainArea__2OuAk {
      padding: 1rem 0; } }

.Header_logo__3oN-S {
  padding-left: 1em;
  padding-right: 1em;
  opacity: 1;
  transition: 0.6s opacity ease-in-out;
  transition-delay: 0.1s; }
  @media (max-width: 991px) {
    .Header_logo__3oN-S img {
      max-height: 36px; } }
  @media (min-width: 992px) {
    .Header_logo__3oN-S.Header_isClosed__3zUfr {
      transition: none;
      transition-delay: 0;
      opacity: 0;
      padding: 0;
      width: 0; } }

@media (min-width: 992px) {
  .Header_hamburger__ElHJz {
    margin-bottom: 1em; } }

.Header_userButton__1OCG0 {
  display: -webkit-inline-flex;
  display: inline-flex;
  min-width: 40px;
  min-height: 40px;
  padding: 8px;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap; }
  .Header_userButton__1OCG0:hover, .Header_userButton__1OCG0:focus, .Header_userButton__1OCG0:active {
    background-color: #161e23;
    color: #ffffff;
    text-decoration: none; }
  .Header_userButton__1OCG0.Header_active__2Q9fP {
    background-color: transparent;
    color: #ffffff;
    font-weight: 700; }
  @media (min-width: 992px) {
    .Header_userButton__1OCG0 {
      width: 100%; } }

.Header_userLabel__3Hd7r {
  display: none; }
  @media (min-width: 992px) {
    .Header_userLabel__3Hd7r {
      display: inline; } }

@media (min-width: 992px) {
  .Header_isClosed__3zUfr .Header_inner__3j-ZN {
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    -webkit-align-items: center;
            align-items: center; }
  .Header_isClosed__3zUfr .Header_userButton__1OCG0 {
    width: auto;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .Header_isClosed__3zUfr .Header_hamburger__ElHJz {
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center; } }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Navigation_root__koSDx {
  z-index: 9;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  left: 0;
  width: 300px;
  display: flex;
  background-color: #202b33;
  color: white;
  overflow-y: auto; }
  @media (min-width: 992px) {
    .Navigation_root__koSDx {
      width: auto;
      height: auto; } }

.Navigation_inner__2JeKc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding: 12px; }

.Navigation_menu__3w2Y2 {
  overflow: hidden;
  padding: 0;
  font-size: 1.1em; }
  .Navigation_menu__3w2Y2 .bp3-popover-target {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .Navigation_menu__3w2Y2 a {
    width: 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
    min-width: 40px;
    min-height: 40px;
    padding: 8px;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.4);
    transition: background-color color 0.3s ease-in-out;
    white-space: nowrap;
    -webkit-align-items: center;
            align-items: center; }
    .Navigation_menu__3w2Y2 a:hover, .Navigation_menu__3w2Y2 a:focus, .Navigation_menu__3w2Y2 a:active {
      background-color: #161e23;
      color: #ffffff;
      text-decoration: none; }
    .Navigation_menu__3w2Y2 a.Navigation_active__1RNy2 {
      background-color: transparent;
      color: #ffffff;
      font-weight: 700; }
  .Navigation_menu__3w2Y2 .Navigation_label__2xLVH {
    margin-left: 0.6em; }

@media (min-width: 992px) {
  .Navigation_isClosed__1KkJZ .Navigation_inner__2JeKc {
    padding-left: 8px;
    padding-right: 8px; }
  .Navigation_isClosed__1KkJZ .Navigation_menu__3w2Y2 a {
    width: auto;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .Navigation_isClosed__1KkJZ .Navigation_label__2xLVH {
    display: none; } }

.DataListItem_root__2I2L2 {
  display: grid;
  grid-template-columns: 1fr 5fr 3fr 3fr;
  grid-gap: 16px;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px 15px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.DataList_root__2uMBJ {
  border: 1px solid #e1e8ed;
  border-radius: 3px;
  overflow: hidden; }
  .DataList_root__2uMBJ li {
    border-bottom: 1px solid #e1e8ed;
    background-color: #ffffff;
    margin: 0 !important; }
    .DataList_root__2uMBJ li:nth-child(even) {
      background-color: #f5f8fa; }
    .DataList_root__2uMBJ li:last-child {
      border-bottom: 0; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Pagination_root__1ocxj {
  display: -webkit-flex;
  display: flex;
  padding: 0;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  font-weight: 600; }
  .Pagination_root__1ocxj li {
    display: inline-block; }

.Pagination_link__7O43V {
  display: block;
  min-width: 2em;
  height: 2em;
  line-height: 2em;
  margin: 1em;
  border: 1px solid transparent;
  color: inherit; }

.Pagination_disabled__3dUns {
  pointer-events: none; }
  .Pagination_disabled__3dUns .Pagination_link__7O43V {
    color: #8a9ba8; }

.selected .Pagination_link__7O43V {
  border: 1px solid #e1e8ed;
  border-radius: 2px;
  background-color: #f5f8fa;
  color: #29a634; }

.ViewLoader_root__24F1O {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.PageHeader_root__3seGW {
  margin: -12px -16px 12px -16px;
  padding: 0 16px;
  height: 64px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  border-bottom: 1px solid #d8e1e8;
  color: #202b33;
  background-color: #ffffff; }

.PageHeader_fixed__3rp6u {
  position: fixed;
  top: 0;
  left: 240px;
  right: 0;
  margin: 0;
  transition: 0.3s left ease-in-out; }
  @media (min-width: 992px) {
    .PageHeader_fixed__3rp6u {
      z-index: 10; } }

.Navigation-closed .PageHeader_fixed__3rp6u {
  left: 60px;
  transition: none; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ImageLabel_root__3Emcs {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.ImageLabel_image__11qci {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e1e8ed; }
  .ImageLabel_image__11qci.ImageLabel_loaded__1qJB1 {
    padding: 0; }
  .ImageLabel_image__11qci.ImageLabel_error__2naPW {
    display: none; }

.ImageLabel_label__g3py8 {
  margin-left: 0.3em;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ImageUpload_block__35X-b {
  position: relative;
  background: #ced9e0;
  border-radius: 0.2em;
  cursor: pointer;
  overflow: hidden; }
  .ImageUpload_block__35X-b img {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.ImageUpload_block--circle__2O11Z {
  border-radius: 50%; }

.ImageUpload_overlay__3dsIi {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.6em;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 1.4rem;
  transition: 0.2s all ease-in-out; }

.ImageUpload_block__35X-b:hover .ImageUpload_overlay__3dsIi {
  opacity: 1; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ResourceListPage_content__28yMS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 20px; }

.ResourceListPage_tab__1p_Uf {
  display: block;
  -webkit-align-items: center;
          align-items: center;
  color: inherit;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  border-bottom: 3px solid transparent;
  margin-right: 20px;
  white-space: nowrap; }
  .ResourceListPage_tab__1p_Uf:last-child {
    margin-right: 0; }
  .ResourceListPage_tab__1p_Uf:hover, .ResourceListPage_tab__1p_Uf:focus, .ResourceListPage_tab__1p_Uf:active {
    color: #106ba3; }
  .ResourceListPage_tab__1p_Uf.ResourceListPage_active__1lL-r {
    color: #106ba3;
    border-color: #106ba3; }

.Overlay_content__1MOOO {
  margin: 2rem;
  padding: 2rem 0;
  background: #fff; }

.Overlay_icon__2q5BH {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.UploadMedia_root__7OMpV {
  overflow: hidden;
  padding-bottom: 0 !important; }

.UploadMedia_stack__r-MXB {
  border-top: 1px solid #e1e8ed;
  position: relative;
  transition: 0.4s height ease-in-out;
  transition-delay: 0.2s; }
  .UploadMedia_stack__r-MXB .bp3-panel-stack {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.UploadMedia_actions__OpD_6 {
  padding: 1em;
  border-top: 1px solid #e1e8ed;
  text-align: right; }

.UploadMedia_dropzoneWrapper__1I1ii {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  min-height: 470px; }

.UploadMedia_dropzone__1DELv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.UploadMedia_resultWrapper__1vEfc {
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  overflow: hidden;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }
  .UploadMedia_resultWrapper__1vEfc img {
    max-height: none; }

.UploadMedia_resultWrapper--circle__NCEoB img {
  border-radius: 50%; }

.Crop_wrapper__2zaCb {
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  overflow: hidden;
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }
  .Crop_wrapper__2zaCb img {
    max-height: none; }

.Crop_Crop--circle__2EZ6M .ReactCrop__crop-selection {
  border-radius: 50%; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
/*
  This loop will create "margin helpers" and "padding helpers" for use in your web projects.
  It will generate several classes such as:
  .m-r-10 which gives margin-right 10 pixels.
  .m-r-15 gives MARGIN to the RIGHT 15 pixels.
  .m-t-15 gives MARGIN to the TOP 15 pixels and so on.
  .p-b-5 gives PADDING to the BOTTOM of 5 pixels
  .p-l-40 gives PADDING to the LEFT of 40 pixels
  The first letter is "m" or "p" for MARGIN or PADDING
  Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
  Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/
.m-t-0 {
  margin-top: 0px !important; }

.p-t-0 {
  padding-top: 0px !important; }

.m-b-0 {
  margin-bottom: 0px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.m-l-0 {
  margin-left: 0px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.m-r-0 {
  margin-right: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.m-t-5 {
  margin-top: 5px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.m-r-35 {
  margin-right: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.m-r-40 {
  margin-right: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.m-t-45 {
  margin-top: 45px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.m-l-45 {
  margin-left: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.m-r-45 {
  margin-right: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.m-r-50 {
  margin-right: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.m-t-75 {
  margin-top: 75px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.m-b-75 {
  margin-bottom: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.m-l-75 {
  margin-left: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.m-r-75 {
  margin-right: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.m-t-100 {
  margin-top: 100px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.m-l-100 {
  margin-left: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.m-r-100 {
  margin-right: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

/*
  Display types
*/
.block {
  display: block; }

.in-block {
  display: inline-block; }

/*
  Size helpers
*/
.fill {
  width: 100%;
  height: 100%; }

/*
  Alignment helpers
*/
.align-right {
  margin-left: auto; }

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important; }

.justify-content-sb {
  -webkit-justify-content: space-between;
          justify-content: space-between; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
body {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0.02em; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

a {
  transition: 0.2s all ease-in-out; }
  a:hover, a:focus, a:active {
    color: #a51800;
    text-decoration: none; }

figure img {
  margin: 0.5em; }

img {
  max-width: 100%;
  display: -webkit-flex;
  display: flex; }

button {
  cursor: pointer; }

blockquote {
  margin: 0;
  padding: 2em 1em 2em 2em;
  border-left: 2px solid #5c7080;
  background-color: #ebf1f5; }

/*
  Text alignments
*/
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

/*
  Text colors
*/
.text-red {
  color: #c23030; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
input, textarea {
  width: 100%;
  border: 1px solid #bfccd6;
  border-radius: 3px;
  height: 30px;
  padding: 0 10px;
  line-height: 30px; }

.bp3-inline .bp3-form-content,
.bp3-inline .bp3-input-group {
  width: 100%; }

.bp3-form-group.bp3-inline {
  -webkit-align-items: center;
          align-items: center; }
  .bp3-form-group.bp3-inline label.bp3-label {
    white-space: nowrap; }

.bp3-switch.bp3-large {
  font-size: inherit; }

.bp3-select-popover .bp3-popover-content {
  padding: 0.4rem; }

.bp3-select-popover .bp3-menu {
  padding: 0.4rem 0; }

.bp3-file-input {
  width: 100%; }

.bp3-intent-danger .bp3-file-upload-input {
  box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0), inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2); }

.dropzone {
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #d8e1e8;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s all ease-out; }

.dropzone:hover {
  border-color: #ced9e0; }

.bp3-control {
  text-transform: capitalize; }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#root {
  height: 100vh;
  min-height: 100vh; }

.row {
  -webkit-flex-shrink: 0;
          flex-shrink: 0; }

figure {
  margin: 0; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.clearfix:before,
.clearfix:after {
  content: "";
  clear: both; }

.bp3-tag {
  padding: 4px 6px; }

table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid black; }
  table tr :last-child td {
    border-bottom: 0; }
  table tr:nth-child(2n) {
    background-color: #eff8ff; }
  table th,
  table td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black; }
    table th :last-child,
    table td :last-child {
      border-right: 0; }
  table .bp3-form-group {
    margin: 0; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.StatsOverview_stat__21iNf {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 3em;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #5c7080;
  font-size: 0.8rem; }
  .StatsOverview_stat__21iNf strong {
    font-size: 2em;
    margin: 0 0.3em; }

.StatsOverview_horizontal__3afh6 {
  -webkit-flex-direction: row;
          flex-direction: row; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.EventItem_root__FGbui {
  display: block;
  padding: 0.8rem 1rem;
  color: inherit; }

.EventItem_date__d3VRF {
  font-size: 0.8rem; }

.EventItem_meta__1IZ9K {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.EventItem_versusTag__1ySm5 {
  font-size: 0.6rem;
  margin-left: 0.5em;
  margin-right: 0.5em; }

.Dashboard_overview__w5h4u {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 0.5em;
  padding-bottom: 0.8em; }

.Dashboard_stats__2OQbX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Separator_root__1dh7h {
  display: inline-block;
  border-right: 1px solid #ebf1f5;
  height: 64px;
  vertical-align: middle;
  margin: 0 1em; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.PDF_root__1ivK1 {
  display: inline-block;
  margin: 0.8rem;
  padding: 0.4rem;
  background-color: #e1e8ed;
  border: 1px solid #ced9e0;
  border-radius: 3px;
  text-align: center; }
  .PDF_root__1ivK1 canvas {
    margin: 0.4rem auto 0 auto; }

.PDF_name__eeoQQ {
  display: block;
  margin-top: 0.4rem;
  font-size: 0.8em;
  color: #394b59; }

.PDF_size__1x1N_ {
  display: block;
  font-size: 0.8em;
  color: #5c7080; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.PublishModal_left__2rIdt {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between; }

.PublishModal_top__1mtYS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.PublishModal_bottom__1jJJc {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .PublishModal_bottom__1jJJc .PublishModal_actions__29Uda {
    -webkit-flex-direction: column;
            flex-direction: column; }

.Sidebar-root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2), 0 18px 46px 6px rgba(16, 22, 26, 0.2);
  background: #fff;
  width: 15vw;
  min-width: 300px;
  max-width: 800px;
  padding: 0;
  overflow: auto; }

.Backdrop {
  opacity: 0;
  transition: opacity .2s ease-in; }

.Transition-enter {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  .Transition-enter-done.Sidebar-root {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }
  .Transition-enter-done.Backdrop {
    opacity: 1;
    transition: opacity .2s ease-in-out; }

.Transition-exit {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  .Transition-exit-active.Sidebar-root {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: .1s;
    transition-timing-function: cubic-bezier(0.4, 1, 0.75, 0.9);
    transition-delay: 0; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.SettingsSidebar_header__3hUGR {
  display: -webkit-flex;
  display: flex;
  padding: 0 15px;
  margin: 20px 0; }

.SettingsSidebar_status__3vw41 {
  display: -webkit-flex;
  display: flex; }

.SettingsSidebar_list__1OBxL > li {
  padding: 5px 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #ebf1f5; }
  .SettingsSidebar_list__1OBxL > li > button {
    font-weight: 500;
    font-size: 14px !important; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ArticleView_root__2Ge5f {
  padding-top: 64px; }

.bp3-editable-text.ArticleView_title__2N2S6 {
  padding: 0;
  font-size: 3rem;
  font-weight: 600;
  height: 3.5rem;
  line-height: 1;
  border-color: transparent;
  outline: none;
  resize: none; }
  .bp3-editable-text.ArticleView_title__2N2S6:hover:before {
    box-shadow: none !important; }
  .bp3-editable-text.ArticleView_title__2N2S6.bp3-editable-text-editing:before {
    border: none !important;
    box-shadow: none !important; }

.ArticleView_input__3NP8x {
  width: 100%;
  min-width: 30rem; }

@media (min-width: 992px) {
  .ArticleView_contentWrapper__K2VpA {
    margin: 30px 4.5rem 0 4.5rem; } }

.ArticleView_editorWrapper__Uryoi {
  margin-top: 2rem;
  position: relative;
  font-size: 18px; }

.public-DraftEditor-content {
  min-height: calc(100vh - 150px); }

.ArticleView_buttonWrapper__SluPP {
  display: inline-block; }
  .ArticleView_buttonWrapper__SluPP + .ArticleView_buttonWrapper__SluPP {
    margin-left: 5px; }

.ArticleView_button__2LtrF {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px;
  color: #182026;
  background-color: #fff;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }

.ArticleView_button__2LtrF svg {
  fill: #394b59;
  vertical-align: middle;
  width: 20px;
  height: 20px; }

.ArticleView_button__2LtrF:hover, .ArticleView_button__2LtrF:focus {
  border-color: #394b59;
  outline: 0; }

.ArticleView_active__24G4a {
  background: #394b59;
  border-color: #394b59;
  color: #ffffff; }
  .ArticleView_active__24G4a svg {
    vertical-align: middle;
    fill: #ffffff; }

.ArticleView_actions__3cSyj {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.ArticleView_savingStatus__3reAP {
  display: -webkit-inline-flex;
  display: inline-flex;
  color: #5c7080; }
  .ArticleView_savingStatus__3reAP.ArticleView_success__2h_Vh {
    color: #29a634; }
  .ArticleView_savingStatus__3reAP.ArticleView_error__fwmov {
    color: #db3737; }

.ArticleView_assignee__1kdEX {
  display: inline-block;
  padding: 0.3em 0.5em;
  margin-left: -0.5em;
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer; }
  .ArticleView_assignee__1kdEX:hover {
    background-color: #e1e8ed; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.AlignmentTool_alignmentTool__fmJwW {
  left: 50%;
  -webkit-transform: translate(-50%) scale(0);
          transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid #bfccd6;
  background: #f5f8fa;
  border-radius: 3px;
  padding: 5px;
  z-index: 2;
  box-sizing: border-box; }

.AlignmentTool_alignmentTool__fmJwW:after,
.AlignmentTool_alignmentTool__fmJwW:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.AlignmentTool_alignmentTool__fmJwW:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #f5f8fa;
  border-width: 4px;
  margin-left: -4px; }

.AlignmentTool_alignmentTool__fmJwW:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #f5f8fa;
  border-width: 6px;
  margin-left: -6px; }

.AlignmentTool_buttonWrapper__23Oxr {
  display: inline-block; }
  .AlignmentTool_buttonWrapper__23Oxr + .AlignmentTool_buttonWrapper__23Oxr {
    margin-left: 5px; }

.AlignmentTool_button__WYxjD {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px;
  color: #182026;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1); }

.AlignmentTool_button__WYxjD svg {
  fill: #394b59;
  vertical-align: middle;
  width: 20px;
  height: 20px; }

.AlignmentTool_button__WYxjD:hover, .AlignmentTool_button__WYxjD:focus {
  border-color: #394b59;
  outline: 0; }

.AlignmentTool_active__1UizY {
  background: #394b59;
  border-color: #394b59;
  color: #ffffff; }
  .AlignmentTool_active__1UizY svg {
    vertical-align: middle;
    fill: #ffffff; }

.NewspaperListItem {
  display: grid;
  grid-template-columns: 1fr 5fr 2fr 3fr 3fr;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px 20px; }

.BettingCardListItem {
  display: grid;
  grid-template-columns: 3fr 2fr 3fr 3fr 3fr;
  -webkit-align-items: center;
          align-items: center;
  padding: 5px 20px; }

.User_tabs__3kO-q {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column; }

.bp3-tab-panel.User_tab__1HqJv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ScreenSizeSwitcher_root__uREsF {
  text-align: center; }

.ScreenSizeSwitcher_device__2nr3Q {
  cursor: pointer; }
  .ScreenSizeSwitcher_device__2nr3Q img {
    display: block;
    max-height: 24px;
    transition: 0.3s all ease-in-out; }
  .ScreenSizeSwitcher_device__2nr3Q.ScreenSizeSwitcher_rotate__1g6ii:hover img, .ScreenSizeSwitcher_device__2nr3Q.ScreenSizeSwitcher_rotate__1g6ii:focus img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .ScreenSizeSwitcher_device__2nr3Q.ScreenSizeSwitcher_landscape__1Kbem img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .ScreenSizeSwitcher_device__2nr3Q.ScreenSizeSwitcher_landscape__1Kbem.ScreenSizeSwitcher_rotate__1g6ii:hover img, .ScreenSizeSwitcher_device__2nr3Q.ScreenSizeSwitcher_landscape__1Kbem.ScreenSizeSwitcher_rotate__1g6ii:focus img {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }

.smartphone {
  max-height: 98%; }

.tablet {
  max-height: 100%; }

.monitor {
  max-height: 95%; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.DeviceFrame_root__3wELo {
  margin: auto;
  text-align: center; }

.DeviceFrame_device__31-g3 {
  display: inline-block;
  position: relative;
  box-sizing: content-box !important; }
  .DeviceFrame_device__31-g3 .DeviceFrame_screen__3Fa4J {
    width: 100%;
    position: relative;
    height: 100%;
    z-index: 3;
    background: white;
    overflow: hidden;
    display: block;
    border-radius: 1px;
    box-shadow: 0 0 0 3px #111; }
  .DeviceFrame_device__31-g3 .DeviceFrame_topBar__3-_Hp, .DeviceFrame_device__31-g3 .DeviceFrame_bottomBar__3a5Ou {
    height: 3px;
    background: black;
    width: 100%;
    display: block; }
  .DeviceFrame_device__31-g3 .DeviceFrame_middle-bar__3BFcm {
    width: 3px;
    height: 4px;
    top: 0px;
    left: 90px;
    background: black;
    position: absolute; }
  .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_ {
    width: 576px;
    height: 768px;
    padding: 90px 25px;
    background: #242324;
    border-radius: 44px; }
    .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_:before {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      position: absolute;
      content: '';
      display: block;
      top: 4px;
      left: 4px;
      border-radius: 40px;
      background: #1e1e1e; }
    .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_ .DeviceFrame_camera__1GSXr {
      background: #3c3d3d;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 44px;
      left: 50%;
      margin-left: -5px;
      border-radius: 100%; }
    .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_ .DeviceFrame_topBar__3-_Hp, .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_ .DeviceFrame_bottomBar__3a5Ou {
      display: none; }
    .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_ .DeviceFrame_home__3RS8V {
      background: #242324;
      border-radius: 36px;
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      margin-left: -25px;
      bottom: 22px; }
      .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_ .DeviceFrame_home__3RS8V:after {
        width: 15px;
        height: 15px;
        margin-top: -8px;
        margin-left: -8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        left: 50%; }
    .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_.DeviceFrame_landscape__xy8G5 {
      height: 576px;
      width: 768px;
      padding: 25px 90px; }
      .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_.DeviceFrame_landscape__xy8G5 .DeviceFrame_camera__1GSXr {
        left: calc(100% - 44px);
        top: 50%;
        margin-left: 0;
        margin-top: -5px; }
      .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_.DeviceFrame_landscape__xy8G5 .DeviceFrame_home__3RS8V {
        top: 50%;
        left: 22px;
        margin-left: 0;
        margin-top: -25px; }
    .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_.DeviceFrame_silver__1rTgj {
      background: #bcbcbc; }
      .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_.DeviceFrame_silver__1rTgj:before {
        background: #fcfcfc; }
      .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_.DeviceFrame_silver__1rTgj .DeviceFrame_home__3RS8V {
        background: #fcfcfc;
        box-shadow: inset 0 0 0 1px #bcbcbc; }
        .DeviceFrame_device__31-g3.DeviceFrame_ipad__2QeQ_.DeviceFrame_silver__1rTgj .DeviceFrame_home__3RS8V:after {
          border: 1px solid rgba(0, 0, 0, 0.2); }
  .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM {
    width: 960px;
    height: 600px;
    padding: 44px 44px 76px;
    margin: 0 auto;
    background: #bebebe;
    border-radius: 34px; }
    .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM:before {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      position: absolute;
      content: '';
      display: block;
      top: 4px;
      left: 4px;
      border-radius: 30px;
      background: #1e1e1e; }
    .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_topBar__3-_Hp {
      width: calc(100% + 2 * 70px);
      height: 40px;
      position: absolute;
      content: '';
      display: block;
      top: 680px;
      left: -70px;
      border-bottom-left-radius: 90px 18px;
      border-bottom-right-radius: 90px 18px;
      background: #bebebe;
      box-shadow: inset 0px -4px 13px 3px rgba(34, 34, 34, 0.6); }
      .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_topBar__3-_Hp:before {
        width: 100%;
        height: 24px;
        content: '';
        display: block;
        top: 0;
        left: 0;
        background: #f0f0f0;
        border-bottom: 2px solid #aaa;
        border-radius: 5px;
        position: relative; }
      .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_topBar__3-_Hp:after {
        width: 16%;
        height: 14px;
        content: '';
        display: block;
        top: 0;
        background: #ddd;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        border-radius: 0 0 20px 20px;
        box-shadow: inset 0px -3px 10px #999; }
    .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_bottomBar__3a5Ou {
      background: transparent;
      width: calc(100% + 2 * 70px);
      height: 26px;
      position: absolute;
      content: '';
      display: block;
      top: 680px;
      left: -70px; }
      .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_bottomBar__3a5Ou:before, .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_bottomBar__3a5Ou:after {
        height: calc(100% - 2px);
        width: 80px;
        content: '';
        display: block;
        top: 0;
        position: absolute; }
      .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_bottomBar__3a5Ou:before {
        left: 0;
        background: #f0f0f0;
        background: linear-gradient(to right, #747474 0%, #c3c3c3 5%, #ebebeb 14%, #979797 41%, #f0f0f0 80%, #f0f0f0 100%, #f0f0f0 100%); }
      .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_bottomBar__3a5Ou:after {
        right: 0;
        background: #f0f0f0;
        background: linear-gradient(to right, #f0f0f0 0%, #f0f0f0 0%, #f0f0f0 20%, #979797 59%, #ebebeb 86%, #c3c3c3 95%, #747474 100%); }
    .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_camera__1GSXr {
      background: #3c3d3d;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 20px;
      left: 50%;
      margin-left: -5px;
      border-radius: 100%; }
    .DeviceFrame_device__31-g3.DeviceFrame_macbook__1ESAM .DeviceFrame_home__3RS8V {
      display: none; }
  .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK {
    width: 375px;
    height: 812px;
    padding: 26px;
    background: #fdfdfd;
    box-shadow: inset 0 0 11px 0 black;
    border-radius: 66px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_overflow__3AsuQ {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 66px;
      overflow: hidden; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_shadow__39X_b {
      border-radius: 100%;
      width: 90px;
      height: 90px;
      position: absolute;
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 60%); }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_shadowTL__1ln8- {
      top: -20px;
      left: -20px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_shadowTR__3nLpA {
      top: -20px;
      right: -20px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_shadowBL__2CY_M {
      bottom: -20px;
      left: -20px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_shadowBR__2AlY8 {
      bottom: -20px;
      right: -20px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK:before {
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      position: absolute;
      top: 5px;
      content: '';
      left: 5px;
      border-radius: 61px;
      background: black;
      z-index: 1; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_innerShadow__1nw2- {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      position: absolute;
      top: 10px;
      overflow: hidden;
      left: 10px;
      border-radius: 56px;
      box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.66);
      z-index: 1; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_innerShadow__1nw2-:before {
        box-shadow: inset 0 0 20px 0 #FFFFFF;
        width: 100%;
        height: 116%;
        position: absolute;
        top: -8%;
        content: '';
        left: 0;
        border-radius: 200px / 112px;
        z-index: 2; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_screen__3Fa4J {
      border-radius: 40px;
      box-shadow: none; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_topBar__3-_Hp, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_bottomBar__3a5Ou {
      width: 100%;
      position: absolute;
      height: 8px;
      background: rgba(0, 0, 0, 0.1);
      left: 0; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_topBar__3-_Hp {
      top: 80px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_bottomBar__3a5Ou {
      bottom: 80px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_volume__229FZ, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_volume__229FZ:before, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_volume__229FZ:after, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_sleep__cHqOE {
      width: 3px;
      background: #b5b5b5;
      position: absolute; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_volume__229FZ {
      left: -3px;
      top: 116px;
      height: 32px; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_volume__229FZ:before {
        height: 62px;
        top: 62px;
        content: '';
        left: 0; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_volume__229FZ:after {
        height: 62px;
        top: 140px;
        content: '';
        left: 0; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_sleep__cHqOE {
      height: 96px;
      top: 200px;
      right: -3px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_camera__1GSXr {
      width: 6px;
      height: 6px;
      top: 9px;
      border-radius: 100%;
      position: absolute;
      left: 154px;
      background: #0d4d71; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_speaker__Loef1 {
      height: 6px;
      width: 60px;
      left: 50%;
      position: absolute;
      top: 9px;
      margin-left: -30px;
      background: #171818;
      border-radius: 6px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_notch__1b8BI {
      position: absolute;
      width: 210px;
      height: 30px;
      top: 26px;
      left: 108px;
      z-index: 4;
      background: black;
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_notch__1b8BI:before, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_notch__1b8BI:after {
        content: '';
        height: 8px;
        position: absolute;
        top: 0;
        width: 8px; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_notch__1b8BI:after {
        background: radial-gradient(circle at bottom left, transparent 0, transparent 70%, black 70%, black 100%);
        left: -8px; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK .DeviceFrame_notch__1b8BI:before {
        background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%);
        right: -8px; }
    .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 {
      height: 375px;
      width: 812px; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_topBar__3-_Hp, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_bottomBar__3a5Ou {
        width: 8px;
        height: 100%;
        top: 0; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_topBar__3-_Hp {
        left: 80px; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_bottomBar__3a5Ou {
        right: 80px;
        bottom: auto;
        left: auto; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_volume__229FZ, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_volume__229FZ:before, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_volume__229FZ:after, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_sleep__cHqOE {
        height: 3px; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_innerShadow__1nw2-:before {
        height: 100%;
        width: 116%;
        left: -8%;
        top: 0;
        border-radius: 112px / 200px; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_volume__229FZ {
        bottom: -3px;
        top: auto;
        left: 116px;
        width: 32px; }
        .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_volume__229FZ:before {
          width: 62px;
          left: 62px;
          top: 0; }
        .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_volume__229FZ:after {
          width: 62px;
          left: 140px;
          top: 0; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_sleep__cHqOE {
        width: 96px;
        left: 200px;
        top: -3px;
        right: auto; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_camera__1GSXr {
        left: 9px;
        bottom: 154px;
        top: auto; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_speaker__Loef1 {
        width: 6px;
        height: 60px;
        left: 9px;
        top: 50%;
        margin-top: -30px;
        margin-left: 0; }
      .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_notch__1b8BI {
        height: 210px;
        width: 30px;
        left: 26px;
        bottom: 108px;
        top: auto;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 0; }
        .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_notch__1b8BI:before, .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_notch__1b8BI:after {
          left: 0; }
        .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_notch__1b8BI:after {
          background: radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%);
          bottom: -8px;
          top: auto; }
        .DeviceFrame_device__31-g3.DeviceFrame_iphoneX__3ChOK.DeviceFrame_landscape__xy8G5 .DeviceFrame_notch__1b8BI:before {
          background: radial-gradient(circle at top right, transparent 0, transparent 70%, black 70%, black 100%);
          top: -8px; }

.DeviceFrame_device__31-g3, .DeviceFrame_device__31-g3 div, *:before, *:after {
  transition: all 500ms cubic-bezier(0.175, 0.785, 0.32, 0.975); }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.App_root__1ACqr {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100vh; }

.App_wrapper__2uWNe {
  display: -webkit-flex;
  display: flex;
  width: 100%; }

.App_sidebar__1gixS {
  z-index: 10;
  position: fixed;
  width: 240px;
  padding-top: 64px;
  transition: 0.3s width ease-in-out; }
  .App_sidebar__1gixS.App_isClosed__1vex4 {
    left: -100%;
    transition: none; }
  @media (min-width: 992px) {
    .App_sidebar__1gixS {
      position: relative;
      padding-top: 0; }
      .App_sidebar__1gixS.App_isClosed__1vex4 {
        left: 0;
        width: 60px; } }
  .App_sidebar__1gixS .App_sidebarInner__1bitr {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: #202b33;
    height: 100vh;
    overflow: hidden; }

.App_main__1vyQo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding: 70px 16px 12px 16px; }
  @media (min-width: 992px) {
    .App_main__1vyQo {
      height: 100vh;
      padding: 12px 16px;
      overflow: auto; } }

.App_logout__eUkmg {
  margin-top: auto;
  padding: 0.6rem 0.2rem;
  border: 1px solid #2a3843;
  background-color: #2a3843;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  transition: 0.2s all ease-in-out;
  white-space: nowrap; }
  .App_logout__eUkmg:hover, .App_logout__eUkmg:focus, .App_logout__eUkmg:active {
    border-color: #161e23;
    background-color: #161e23;
    color: #ffffff; }
  .App_logout__eUkmg .App_logoutLabel__3sk4R {
    margin-left: 10px;
    transition: 0.3s all ease-in; }
  .App_logout__eUkmg.App_isClosed__1vex4 {
    padding-left: 22px; }
    .App_logout__eUkmg.App_isClosed__1vex4 .App_logoutLabel__3sk4R {
      opacity: 0; }

.Login_logo__1ldNV {
  text-align: center;
  margin-bottom: 2rem; }

