@import 'shared/styles/Variables.scss';

.block {
  position: relative;
  background: $light-gray1;
  border-radius: 0.2em;
  cursor: pointer;
  overflow: hidden;
  img {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.block--circle {
  border-radius: 50%;
}

.overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.6em;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  flex: 1 1;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 1.4rem;
  transition: 0.2s all ease-in-out;
}

.block:hover .overlay {
  opacity: 1;
}
