@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~flexboxgrid/dist/flexboxgrid.css";

@import "./Variables.scss";
@import "./Utilities.scss";
@import "./Typography.scss";
@import "./Inputs.scss";

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
  min-height: 100vh;
}

.row {
  flex-shrink: 0;
}

figure {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clearfix:before,
.clearfix:after {
  content: "";
  clear: both;
}

.bp3-tag {
  padding: 4px 6px;
}

table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid black;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }

    &:nth-child(2n) {
      background-color: #eff8ff;
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }

  .bp3-form-group {
    margin: 0;
  }
}
