@import "shared/styles/Variables.scss";

.root {
  overflow: hidden;
  padding-bottom: 0 !important;
}

.stack {
  border-top: 1px solid $light-gray3;
  position: relative;
  transition: 0.4s height ease-in-out;
  transition-delay: 0.2s;
  :global .bp3-panel-stack {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.actions {
  padding: 1em;
  border-top: 1px solid $light-gray3;
  text-align: right;
}

.dropzoneWrapper {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 470px;
}
.dropzone {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.resultWrapper {
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  overflow: hidden;
  flex-shrink: 0;
  img {
    max-height: none;
  }
}

.resultWrapper--circle {
  img {
    border-radius: 50%;
  }
}
