@import "shared/styles/Variables.scss";

.root {
  z-index: 9;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  left: 0;
  width: 300px;
  display: flex;
  background-color: $sidebarBackground;
  color: $sidebarColor;
  overflow-y: auto;
  @media (min-width: $large-screen-min) {
    width: auto;
    height: auto;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}

.menu {
  overflow: hidden;
  padding: 0;
  font-size: 1.1em;
  :global .bp3-popover-target {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    width: 100%;
    display: inline-flex;
    min-width: 40px;
    min-height: 40px;
    padding: 8px;
    border-radius: $border-radius-md;
    color: rgba($sidebarColor, 0.4);
    transition: background-color color 0.3s ease-in-out;
    white-space: nowrap;
    align-items: center;
    &:hover, &:focus, &:active {
      background-color: darken($sidebarBackground, 5%);
      color: $white;
      text-decoration: none;
    }
    &.active {
      background-color: transparent;
      color: $white;
      font-weight: 700;
    }
  }
  .label {
    margin-left: 0.6em;
  }
}

.isClosed {
  @media (min-width: $large-screen-min) {
    .inner {
      padding-left: 8px;
      padding-right: 8px;
    }
    .menu a {
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .label {
      display: none;
    }
  }
}
