.wrapper {
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  overflow: hidden;
  flex-shrink: 0;
  img {
    max-height: none;
  }

}
.Crop--circle {
  :global .ReactCrop__crop-selection {
    border-radius: 50%;
  }
}
