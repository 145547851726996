@import "shared/styles/Variables.scss";

.root {
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  li {
    display: inline-block;
  }
}

.link {
  display: block;
  min-width: 2em;
  height: 2em;
  line-height: 2em;
  margin: 1em;
  border: 1px solid transparent;
  color: inherit;
}

.disabled {
  pointer-events: none;
  .link {
    color: $gray3;
  }
}

:global .selected :local .link {
  border: 1px solid $light-gray3;
  border-radius: 2px;
  background-color: $light-gray5;
  color: $forest3;
}
