@import "shared/styles/Variables.scss";

.alignmentTool {
  left: 50%;
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid $gray5;
  background: $light-gray5;
  border-radius: 3px;
  padding: 5px;
  z-index: 2;
  box-sizing: border-box;
}

.alignmentTool:after,
.alignmentTool:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.alignmentTool:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: $light-gray5;
  border-width: 4px;
  margin-left: -4px;
}

.alignmentTool:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: $light-gray5;
  border-width: 6px;
  margin-left: -6px;
}

.buttonWrapper {
  display: inline-block;
  + .buttonWrapper {
    margin-left: 5px;
  }
}

.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  min-width: 30px;
  min-height: 30px;
  color: $dark-gray1;
  background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0));
  box-shadow: inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1);
}

.button svg {
  fill: $dark-gray5;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.button:hover, .button:focus {
  border-color: $dark-gray5;
  outline: 0;
}

.active {
  background: $dark-gray5;
  border-color: $dark-gray5;
  color: $white;
  svg {
    vertical-align: middle;
    fill: $white;
  }
}