@import "shared/styles/Variables.scss";

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  display: flex;
  flex-direction: column;
}

.bottom {
  display: flex;
  flex-direction: column;
  .actions {
    flex-direction: column;
  }
}
