@import 'shared/styles/Variables.scss';

.root {
  flex-direction: column;
  padding: 3rem 16px;
  text-align: center;
  background-color: $white;
  border: 1px solid $light-gray3;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s all ease-out;
}
.root:hover {
  border-color: $light-gray2;
}

.thumbsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  padding: 0.4rem;
};

.thumb {
  display: flex;
  align-items: center;
  margin: 0.4rem;
  padding: 0.2rem;
  background-color: $light-gray3;
  border: 1px solid $light-gray1;
  border-radius: 3px;
  &:last-child {
    margin-bottom: 0;
  }
};

.thumbInner {
  width: 100%;
  overflow: hidden;
  border-radius: 3px;
}

.img {
  display: block;
  width: auto;
};

.hint {
  color: $gray3;
  font-size: 20px;
}
.limits {
  color: $gray4;
}

.page {
  max-width: 100%;
  canvas {
    max-width: 100%;
    height: auto !important;
  }
}
