@import "shared/styles/Variables.scss";

.root {
  display: inline-block;
  margin: 0.8rem;
  padding: 0.4rem;
  background-color: $light-gray3;
  border: 1px solid $light-gray1;
  border-radius: 3px;
  text-align: center;
  canvas {
    margin: 0.4rem auto 0 auto;
  }
}

.name {
  display: block;
  margin-top: 0.4rem;
  font-size: 0.8em;
  color: $dark-gray5;
}

.size {
  display: block;
  font-size: 0.8em;
  color: $gray1;
}
