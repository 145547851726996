@import "shared/styles/Variables.scss";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.wrapper {
  display: flex;
  width: 100%;
}

.sidebar {
  z-index: 10;
  position: fixed;
  width: 240px;
  padding-top: 64px;
  transition: 0.3s width ease-in-out;
  &.isClosed {
    left: -100%;
    transition: none;
  }
  @media (min-width: $large-screen-min) {
    position: relative;
    padding-top: 0;
    &.isClosed {
      left: 0;
      width: 60px;
    }
  }
  .sidebarInner {
    display: flex;
    flex-direction: column;
    background-color: $sidebarBackground;
    height: 100vh;
    overflow: hidden;
  }
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 70px 16px 12px 16px;
  @media (min-width: $large-screen-min) {
    height: 100vh;
    padding: 12px 16px;
    overflow: auto;
  }
}

.logout {
  margin-top: auto;
  padding: 0.6rem 0.2rem;
  border: 1px solid lighten($sidebarBackground, 5%);
  background-color: lighten($sidebarBackground, 5%);
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  transition: 0.2s all ease-in-out;
  white-space: nowrap;
  &:hover, &:focus, &:active {
    border-color: darken($sidebarBackground, 5%);
    background-color: darken($sidebarBackground, 5%);
    color: $white;
  }
  .logoutLabel {
    margin-left: 10px;
    transition: 0.3s all ease-in;
  }
  &.isClosed {
    padding-left: 22px;
    .logoutLabel {
      opacity: 0;
    }
  }
}
