.tabs {
  display: flex;
  flex: 1;
  flex-direction: column;
}

:global .bp3-tab-panel:local.tab {
  display: flex;
  flex-direction: column;
  flex: 1;
}
